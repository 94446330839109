import React from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { pages } from '@/utils/pages';

import { Box, Flex, Link, Text } from '@chakra-ui/react';
import MainWrapper from '../Wrapper';
import { Copyright } from '../illustrations';

const Footer = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const optionsStyles = {
    mx: 3,
    fontWeight: 'bold',
    cursor: 'pointer',
  };

  return (
    <Box mb={{ base: 6, md: 12 }} mt={12}>
      <MainWrapper overflow='hidden'>
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          flexFlow={{ base: 'column', md: 'row' }}
        >
          <Text fontWeight='bold' alignItems='center' ml={1}>
            <Copyright /> {t('welcome:footer.allRights')}
          </Text>
          <Flex>
            <Link {...optionsStyles} onClick={() => router.push(pages.tosPath)}>
              {t('welcome:footer.terms')}
            </Link>
            <Link
              {...optionsStyles}
              onClick={() => router.push(pages.privacyPath)}
            >
              {t('welcome:footer.privacy')}
            </Link>
            <Link {...optionsStyles} onClick={() => router.push(pages.faqPath)}>
              {t('welcome:footer.faq')}
            </Link>
          </Flex>
        </Flex>
      </MainWrapper>
    </Box>
  );
};

export default Footer;
