import React from 'react';
import getConfig from 'next/config';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

import { chakra, Box } from '@chakra-ui/react';
import { slides } from './constants';
import SlideWrapper from './SlideWrapper';
import useTranslation from 'next-translate/useTranslation';
import { useSelector } from 'react-redux';
import { getUser } from '@/stores/user';

import 'swiper/css';
import 'swiper/css/pagination';

const RemySwiper = chakra(Swiper);
const { publicRuntimeConfig } = getConfig();

const WelcomeCarousel = () => {
  const { t } = useTranslation();
  const user = useSelector(state => getUser(state));
  const slideDefinition = slides(t, user?.name);

  return (
    <Box h='full' bg='alpha.400'>
      <RemySwiper
        h='full'
        w='full'
        centeredSlides={true}
        pagination={{ clickable: true }}
        modules={[Autoplay, Pagination]}
        loop={true}
        autoplay={{
          delay: publicRuntimeConfig.CAROUSEL_DELAY,
          disableOnInteraction: false,
        }}
      >
        {slideDefinition.map((slide, index) => (
          <SwiperSlide key={index}>
            <SlideWrapper isFirstSlide={index === 0} text={slide.title} subtext={slide.subtitle} ImageComponent={slide.icon} />
          </SwiperSlide>
        ))}
      </RemySwiper>
    </Box>
  );
};

export default WelcomeCarousel;
