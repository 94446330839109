import WelcomeContainer from '@/containers/WelcomeContainer';
import OutlinesContainer from '@/containers/OutlinesContainer';
import RecommendedOutlinesContainer from '@/containers/RecommendedOutlinesContainer';
import { Box, Flex } from '@chakra-ui/react';
import MainLayout from '@/layouts/MainLayout';
import Footer from '@/components/Footer';

const Welcome = () => {
  return (
    <MainLayout>
      <Flex flexDirection='column' h='full'>
        <WelcomeContainer />
        <Box flexGrow={1}>
          <OutlinesContainer />
          <RecommendedOutlinesContainer />
          <Footer />
        </Box>
      </Flex>
    </MainLayout>
  );
};

export default Welcome;
