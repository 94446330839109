import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useSession } from 'next-auth/client';

import { clearSearch } from '@/stores/search';

import { WelcomeCarousel } from '@/components/Welcome';
import MainWrapper from '@/components/Wrapper';
import Searchbar from '@/components/Searchbar';
import { SignUpButton } from '@/components/SignUpButton';

const WelcomeContainer = () => {
  const dispatch = useDispatch();
  const [session, loading] = useSession();

  useEffect(() => {
    dispatch(clearSearch());
  }, [dispatch]);

  return (
    <Box position='relative' w='full' h={{ base: 'auto', md: '480px' }}>
      <MainWrapper
        display='flex'
        position='absolute'
        left='50%'
        transform='translate(-50%, 0)'
        justifyContent='center'
        w='full'
        zIndex={10}
      >
        <Searchbar />
      </MainWrapper>
      <MainWrapper
        position='absolute'
        top={{ sm: '190px', md: '240px', lg: '210px' }}
        bottom={{ base: '40px' }}
        transition='all .5s'
        left='50%'
        transform='translate(-50%, 0)'
        w='full'
        zIndex={10}
        display={{ base: 'flex', sm: 'block' }}
        justifyContent='center'
      >
        {!loading && (
          <SignUpButton
            session={session}
            lightButton
            w='fit-content'
            display='inline-flex'
            transition='all .5s'
          />
        )}
      </MainWrapper>
      <WelcomeCarousel />
    </Box>
  );
};

export default WelcomeContainer;
