import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import MainWrapper from '@/components/Wrapper';

const SlideWrapper = ({ isFirstSlide, text, subtext, ImageComponent }) => {
  const headingSize = isFirstSlide ? '5xl' : '4xl';
  const textSize = isFirstSlide ? '2xl' : 'xl';

  const extraProps = isFirstSlide ? {
    bg: 'alpha.400'
  } : {
    bgGradient: 'linear(alpha.400 0%, alpha.300 90%)'
  };

  return (
    <Box
      w='full'
      h='full'
      align='center'
      mx={{ base: 0, lg: 'auto' }}
      {... extraProps}
    >

      <MainWrapper
        display='flex'
        w='full'
        h='full'
        overflow='hidden'
        justifyContent='space-between'
        pb={{ base: 4, md: 0 }}
      >
        <Box
          w={{ base: 'full', md: '60%', lg: '50%' }}
          py={{ base: 20, lg: 16 }}
          transition='padding .5s'
          color='white'
          textAlign={{ base: 'center', md: 'left' }}
          position='relative'
          alignSelf={{ base: 'center', md: 'flex-start' }}
        >
          <Heading fontWeight={600} fontSize={{ base: '2xl', md: headingSize }} mb={2}>
            {text}
          </Heading>
          <Text fontSize={{ base: null, md: textSize }}>{subtext}</Text>
        </Box>
        <Flex
          display={{ base: 'none', md: 'flex' }}
          h='full'
          alignSelf='flex-end'
          alignItems='flex-end'
        >
          {ImageComponent}
        </Flex>

      </MainWrapper>
    </Box>
  );
};

export default SlideWrapper;