import React from 'react';
import { SlideWelcome, SlideDownload, SlideUpload, SlideReview } from '../illustrations';

export const slides = (t, name) => [
  {
    title: t('welcome:slides.main.title'),
    subtitle: t('welcome:slides.main.subtitle'),
    icon: <SlideWelcome w={{ md: '640px' }} h={{ md: 'auto' }}/>,
  },
  {
    title: t('welcome:slides.upload.title'),
    subtitle: t('welcome:slides.upload.subtitle'),
    icon: <SlideUpload w={{ md: '290px', lg: '400px' }} h={{ md: 'auto' }} />,
  },
  {
    title: t('welcome:slides.download.title'),
    subtitle: t('welcome:slides.download.subtitle'),
    icon: <SlideDownload w={{ md: '350px', lg: '500px' }} h={{ md: 'auto' }} />,
  },
  {
    title: t('welcome:slides.review.title'),
    subtitle: t('welcome:slides.review.subtitle'),
    icon: <SlideReview w={{ md: '300px', lg: '450px' }} h={{ md: 'auto' }} />,
  }
];

