import React, { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';

import {
  getFirstyearOutlines,
  getFirstyearOutlinesList,
} from '@/stores/recommendedOutlines';

import MainWrapper from '@/components/Wrapper';
import Carousel from '@/components/Carousel';

const OutlinesContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFirstyearOutlines());
  }, [dispatch]);

  const firstyearOutlinesList = useSelector(state =>
    getFirstyearOutlinesList(state),
  );

  return (
    !!firstyearOutlinesList.length && (
      <Box
        bgGradient={
          'linear(318.94deg, gradientAlpha.0 -14.62%, gradientAlpha.100 84.21%)'
        }
        py={10}
      >
        <MainWrapper overflow='hidden'>
          <Carousel
            title={t('carousel:firstYear')}
            outlines={firstyearOutlinesList}
          />
        </MainWrapper>
      </Box>
    )
  );
};

export default OutlinesContainer;
